import React, { useEffect, useState } from 'react'
// import Slider from '../components/Slider'
import Banner from '../components/Banner'
import Articles from '../components/Articles'
import Announcement from '../components/Announcement'
import { useDispatch, useSelector } from 'react-redux'
import StandardLanding from '../../../shared/components/StandardLanding'
import { getAnnouncementList, getArticleList } from '../action'

import Image1 from '../../../shared/assets/images/image1.png';
import Image2 from '../../../shared/assets/images/image2.png';
import Matatag from '../../../shared/assets/images/matatag.png';
import depedLg from '../../../shared/assets/images/deped-lg.png';
import brigada from '../../../shared/assets/images/brigada-eskwela.png';
import disasterPrevention from '../../../shared/assets/images/disaster-prevention.png';
import aas from '../../../shared/assets/images/adopt-a-school.png';

const images = [Image1, Image2, ];

const Landing = () => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state);
    const { announcements, articles, loading } = state.home;

    let [dogImage, setDogImage] = useState([])

    //!revisit: below api call - returns error 500 status
    // 3. Create out useEffect function
    /* useEffect(() => {
      fetch("http://localhost:8004/api/v1/website/announcements")
      .then(response => response.json())
          // 4. Setting *dogImage* to the image url that we received from the response above
      .then(data => setDogImage(data.data))
    },[]) */

    return (
        <StandardLanding>
            <Banner/>
            {/* <Announcement /> */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className='flex justify-center'>
                    <img style={{ width: 200, alignSelf: 'center' }} src={depedLg} alt="" />
                    <img className='mt-10' style={{ width: 600, alignSelf: 'center' }} src={Matatag} alt="" />
                </div>
                <h1 className='mt-16 text-4xl px-10 text-center font-semibold'>Welcome to the Department of Education (DepEd) Partnership Assistance Portal (DPAP)!</h1>
                <p className='text-base mt-5 px-10'> 
                The DPAP is a real-time web portal for partners navigation, search engine, profiling, and downloading for potential education investments study at various tiers from region down to schools, matching their core businesses, operations and mandates with the thematic goals of basic education. It is also a real-time web portal during disasters, like earthquakes, flooding, typhoon among others, uploading, downloading, and updating by the DepEd partnership focal persons on the needed assistance for their schools. The portal is designed with a user-responsive and user-friendly interface for its identified user type so that partnership data can be gathered and analyzed seamlessly, and that information is made available and accessible to support partner/stakeholder decision-making. Also, the partners/stakeholders can easily view the types of assistance that can be made for DepEd during crisis situations.
                </p>
                <p className='text-base mt-5 px-10'>
                With the DPAP, DepEd offers an improved partnership process that includes a better management system for potential partner/s to collaborate and reach an informed agreement where transparency and openness can lead to a more informed partnership. The DPAP also supports the National Education Portal (NEP) strategic goal in making sure there is ease in doing business with DepEd for our partners. 
                </p>
                <p className='text-base mt-5 px-10'>
                The DPAP is for you, education partners!
                </p>
                <h1 className='mt-16 text-4xl px-10 text-center font-semibold'>ROLE OF PARTNERSHIP IN THE DEPED MATATAG AGENDA</h1>
                <p className='text-base mt-5 px-10'>
                The Department of Education (DepEd), in the effort to provide quality education for all, focused on expanding access and ensuring more Filipinos receive basic education. The MATATAG-Bansang Makabata, Batang Makabansa Agenda of the Honorable Vice President and Secretary of Education Sara Z. Duterte is strategically achieved with the cooperation of the various education and development stakeholders through strategic partnership. At DepEd we believe that “it takes a community to raise a child.” Further, the 16 Sustainable Development Goals are anchored on Sustainable Development Goal 17 (SDG 17) which is “strengthen the means of implementation and revitalize the global partnership for sustainable development.”  
                </p>
                <p className='text-base mt-5 px-10'>
                DepEd is continuously finding ways for our partners and education stakeholders to be engaged to address the immediate, and multiple needs of learners and invest to help bridge the gap in education all over the country. The DepEd Partnership Assistance Portal (DPAP) aims to elevate the partnership management processes, reach out to more potential partners, and increase efficiency in bridging the gap between the resource needs of schools to available resources from public and private organizations.
                </p>
                <h1 className='mt-16 text-4xl px-10 text-center font-semibold'>DepEd PARTNERSHIP PROGRAMS</h1>
                <div className='flex justify-center'>
                    <img className='mx-3 my-5' style={{ width: 200, height: 200, alignSelf: 'center' }} src={aas} alt="" />
                    <img className='mx-3 my-5' style={{ width: 200, alignSelf: 'center' }} src={brigada} alt="" />
                    <img className='mx-3 my-5' style={{ width: 200, alignSelf: 'center' }} src={disasterPrevention} alt="" />
                </div>
                <p className='text-base mt-5 px-10'>
                Partners can choose from three DepEd Partnership Programs in supporting the 28 million Filipino learners in more than 45,000 schools nationwide.
                </p>
                <p className='text-base mt-5 px-10'>
                <strong>Adopt-a-School Program.</strong> The Adopt-A-School Program was established through Republic Act 8525 in 1998 as an initiative to encourage private entities to assist in upgrading and modernizing a public school, whether elementary, secondary, or tertiary
                </p>
                <p className='text-base mt-5 px-10'>
                <strong>Brigada Eskwela.</strong> Brigada Eskwela was institutionalized through DepEd Order No. 24, series of 2008  as a school maintenance program nationwide that engages all education stakeholders to contribute their time, efforts and resources in ensuring that public schools are set in time for class opening. It is a week long event where local communities, parents, alumni, civic groups, local businesses, NGOs, private individuals and even teachers and students volunteer their time and skills to do minor to medium repairs and maintenance work in the schools.
                </p>
                <p className='text-base mt-5 px-10'>
                <strong>Disaster Prevention, Response and Rehabilitation.</strong> . As a member of the National Disaster Risk Reduction and Management Council, DepEd continuously provides various interventions on disaster prevention, response and rehabilitation to its learning communities. During calamities and disasters, DepEd ensures that resources are provided to protect the well-being of students and teachers and their families.
                </p>
                <h1 className='mt-16 text-4xl px-10 text-center font-semibold'>PARTNERSHIP INCENTIVES</h1>
                <p className='text-base mt-5 px-10'>
                Education partners can receive up to 150% tax incentives based on their interventions and/or contributions to the schools, schools division offices or regional offices. Furthermore, DepEd holds a Partners’ Appreciation Program annually to recognize the contributions of all its partners from the public and the private sector.
                </p>
            </div>
            <Articles />
        </StandardLanding>
    )
}

export default Landing
