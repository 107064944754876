import React from "react";
import sealph from "../../../shared/assets/images/pts.png";
import dpds from "../../../shared/assets/images/dpap.png";
import deped from "../../../shared/assets/images/deped@2x.png";
import seal from "../../../shared/assets/images/govph-seal-mono-footer.jpg";

const PageFooter = () => {
  return (
    <div style={{ background: '#f3f4f6' }} className="text-gray-700 flex flex-col items-center py-10 footer">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex  footer-main text-gray-700">
        <div className="mr-10">
          <img style={{ width: 120 }} src={seal} alt="" />
        </div>
        <div className="w-80 mr-2 w-dpds">
          <p className="my-3 font-medium">REPUBLIC OF THE PHILIPPINES</p>
          <p className="mb-4 mt-3 pr-20">
            All content is in the public domain unless otherwise stated.
          </p>
        </div>
        <div className="w-100 mr-2">
          <p className="font-bold  my-3 font-medium">
            ABOUT GOVPH
          </p>
          <p className="mb-4 mt-3 pr-20">
            Learn more about the Philippine government, its structure, how government works and the people behind it.
          </p>
          <ul className="nav-footer mt-5">
            <li style={{ marginBottom: 0 }}>
              <a target="_blank" href="https://www.gov.ph/">GOV.PH</a>
            </li>
            <li style={{ marginBottom: 0 }}>
              <a target="_blank" href="http://www.gov.ph/data">Open Data Portal</a>
            </li>
            <li style={{ marginBottom: 0 }}>
              <a target="_blank" href="http://www.officialgazette.gov.ph/">Official Gazette</a>
            </li>
          </ul>
        </div>
        <div className="w-80 mr-2">
          <p className="font-bold  my-3 font-medium">
            GOVERNMENT LINKS
          </p>
          <ul className="nav-footer">
            <li style={{ marginBottom: 0 }}>
              <a target="_blank" href="http://president.gov.ph/">Office of the President</a>
            </li>
            <li style={{ marginBottom: 0 }}>
              <a target="_blank" href="http://ovp.gov.ph/">Office of the Vice President</a>
            </li>
            <li style={{ marginBottom: 0 }}>
              <a target="_blank" href="http://www.senate.gov.ph/">Senate of the Philippines</a>
            </li>
            <li style={{ marginBottom: 0 }}>
              <a target="_blank" href="http://www.congress.gov.ph/">House of Representatives</a>
            </li>
            <li style={{ marginBottom: 0 }}>
              <a target="_blank" href="http://sc.judiciary.gov.ph/">Supreme Court</a>
            </li>
            <li style={{ marginBottom: 0 }}>
              <a target="_blank" href="http://ca.judiciary.gov.ph/">Court of Appeals</a>
            </li>
            <li style={{ marginBottom: 0 }}>
              <a target="_blank" href="http://sb.judiciary.gov.ph/">Sandiganbayan</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
