import { getError, getAuthHeader, axios } from "../../shared/utils";
import {
    API_ARTICLE_LIST,
    API_ANNOUNCEMENT_LIST,
} from "../../shared/constant/url";

export const HOME = {
  ANNOUNCEMENT_LIST_START: "ANNOUNCEMENT_LIST_START",
  ANNOUNCEMENT_LIST_SUCCESS: "ANNOUNCEMENT_LIST_SUCCESS",
  ANNOUNCEMENT_LIST_ERROR: "ANNOUNCEMENT_LIST_ERROR",

  ARTICLE_LIST_START: "ARTICLE_LIST_START",
  ARTICLE_LIST_SUCCESS: "ARTICLE_LIST_SUCCESS",
  ARTICLE_LIST_ERROR: "ARTICLE_LIST_ERROR",
};

export const getAnnouncementList = (callback = null) => {
  return (dispatch) => {
    dispatch({ type: HOME.ANNOUNCEMENT_LIST_START });
    let formData = new FormData();
    let reqOptions = {
      body: formData,
      method: "POST",
    };
    fetch(API_ANNOUNCEMENT_LIST, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch(
          { type: HOME.ANNOUNCEMENT_LIST_SUCCESS, payload: response.data },
        );
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: HOME.ANNOUNCEMENT_LIST_ERROR, payload: getError(error) });
      });
  };
};

export const getArticleList = (callback = null) => {
    return (dispatch) => {
      dispatch({ type: HOME.ARTICLE_LIST_START });
      let formData = new FormData();
      let reqOptions = {
        body: formData,
        method: "POST",
      };
      fetch(API_ARTICLE_LIST, reqOptions)
        .then((response) => response.json())
        .then((response) => {
          callback(response, null);
          dispatch(
            { type: HOME.ARTICLE_LIST_SUCCESS, payload: response.data },
          );
        })
        .catch((error) => {
          callback(null, getError(error));
          dispatch({ type: HOME.ARTICLE_LIST_ERROR, payload: getError(error) });
        });
    };
  };
  
