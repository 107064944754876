import React from 'react';
import { Button } from 'antd';
import image from '../../../shared/images/Assets_DepEd-DPDS/Title.png';
import ArticleCard from './ArticleCard';

const Articles = () => {
  return (
    <div className="">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-20">
        <div className="font-bold flex justify-between items-center w-full mt-20 header-border">
          <div>
            <img src={image} />
            <p className="absolute ml-5 -mt-2 text-2xl">FEATURED ARTICLES</p>
          </div>
        </div>
        <ArticleCard />
      </div>
    </div>
  );
};

export default Articles;
