import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../../home/containers/Landing'
import OrgChart from '../../about-us/containers/OrganizationalChart'
import AboutOverView from '../../about-us/containers/AboutOverView'
import CitizenCharter from '../../about-us/containers/CitizenCharter'
import PublicDashboard from '../../partners/containers/PublicDashboard'
import DepedPartners from '../../partners/containers/DepedPartners'
import Faqs from '../../faqs/containers/Landing'
import ContactUs from '../../contact-us/containers/Landing'
import Login from '../../login/containers/Login'
import ArticleContent1 from '../../home/components/ArticleContent1'
import ArticleContent2 from '../../home/components/ArticleContent2'
import ArticleContent3 from '../../home/components/ArticleContent3'
import ArticleContent4 from '../../home/components/ArticleContent4'
import ArticleContent5 from '../../home/components/ArticleContent5'
import ArticleContent6 from '../../home/components/ArticleContent6'

const PageContent = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about-overview" component={AboutOverView} />
            <Route exact path="/about-citizen" component={CitizenCharter} />
            <Route exact path="/about-orgchart" component={OrgChart} />
            <Route exact path="/public-dashboard" component={PublicDashboard} />
            <Route exact path="/deped-partners" component={DepedPartners} />
            <Route exact path="/faqs" component={Faqs} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route path="/article1" component={ArticleContent1} />
            <Route path="/article2" component={ArticleContent2} />
            <Route path="/article3" component={ArticleContent3} />
            <Route path="/article4" component={ArticleContent4} />
            <Route path="/article5" component={ArticleContent5} />
            <Route path="/article6" component={ArticleContent6} />
            {/* <Route path="/login" component={Login} /> */}
        </Switch>
    )
}

export default PageContent
