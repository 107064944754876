import React from "react";
import styled from "@emotion/styled";

function StandardLanding(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default StandardLanding;

const StyledDiv = styled.div({
  ".main-container": {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // width: "calc(100vw - 50rem); !important",

    
      ".content-header-left": {
        lineHeight: "2.25rem;",
        fontWeight: "600",
        textAlign: "left",
        marginBottom: "2rem",
        textTransform: "uppercase",
      },
      ".content-header": {
        color: "black",
        // fontSize: "1.875rem;",
        lineHeight: "2.25rem;",
        fontWeight: "600",
        textAlign: "center",
        marginBottom: "2rem",
        textTransform: "uppercase",
      },
      ".content-subheader": {
        color: "black",
        fontSize: "1rem;",
        lineHeight: "2rem;",
        fontWeight: "600",
        marginBottom: "2rem",
      },
      ".lg-title": {
        fontSize: "1rem;",
        lineHeight: "2rem;",
        fontWeight: "600",
        marginBottom: "2rem",
      },
      ".md-title": {
        fontSize: "0.9rem;",
        lineHeight: "2rem;",
        fontWeight: "500",
        marginBottom: "2rem",
      },
      ".sm-title": {
        fontSize: "0.7rem;",
        lineHeight: "2rem;",
        fontWeight: "600",
        marginBottom: "2rem",
      },
      ".xs-title": {
        fontSize: "0.5rem;",
        lineHeight: "2rem;",
        fontWeight: "600",
        marginBottom: "2rem",
      },
    },
  ".content-crop": {
    height: "calc(100vh - 72px); !important",
  },

  ".subcontent-crop": {
    height: "calc(100vh - 20rem); !important",
  },
});
