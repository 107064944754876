import React from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import OrgChart from '../components/OrgChart'
import StandardLanding from '../../../shared/components/StandardLanding'

const AboutOverView = () => {
    return (
        <StandardLanding>
            <div>
                <div className="main-banner flex flex-col items-center justify-center text-white">
                    <p className="font-bold text-4xl mb-5">ORGANIZATIONAL STRUCTURE FOR OU-APP</p>
                    <div className="flex font-bold">
                        <Link to="/">Home</Link>
                        <span className="mx-5">|</span>
                        <Link to="/about-overview">About Us</Link>
                    </div>
                </div>
                <OrgChart />
            </div>
        </StandardLanding>
    )
}

export default AboutOverView
