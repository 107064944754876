import { HOME } from './action';

const INITIAL_STATE = {
    loading: false, 
    error: '', 
    announcements: [],
    articles: [],
};


const home = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case HOME.ANNOUNCEMENT_LIST_START:
            return { ...state, loading: true, error: '',isLoggedIn: false }
        case HOME.ANNOUNCEMENT_LIST_SUCCESS:
            return { ...state, loading: false, announcements: action.payload }
        case HOME.ANNOUNCEMENT_LIST_ERROR:
            return { ...state, loading: false, error: action.payload }

        case HOME.ARTICLE_LIST_START:
            return { ...state, loading: true, error: '',isLoggedIn: false }
        case HOME.ARTICLE_LIST_SUCCESS:
            return { ...state, loading: false, articles: action.payload }
        case HOME.ARTICLE_LIST_ERROR:
            return { ...state, loading: false, error: action.payload }

        default: 
            return state;
    }
}

export default home;


