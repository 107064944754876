import React from 'react'
import { Link } from 'react-router-dom'
import ListFaqs from '../components/ListFaqs'
import StandardLanding from '../../../shared/components/StandardLanding'
import FormSearch from '../components/FormSearch'

const Landing = () => {
    return (
        <StandardLanding>
            <div className="">
                <div className="main-banner flex flex-col items-center justify-center text-white w-full">
                    <p className="font-bold text-4xl mb-5">FREQUENTLY ASKED QUESTIONS</p>
                    <div className="flex font-bold">
                        <Link to="/">Home</Link>
                        <span className="mx-5">|</span>
                        <Link to="/faqs">FAQs</Link>
                    </div>
                </div>
                <div className="ct-container  flex flex-center flex-col items-center mt-20">
                    {/* <div className="w-1/2 mb-5">
                        <FormSearch />
                    </div> */}
                    <ListFaqs />
                </div>
            </div>
        </StandardLanding>
    )
}

export default Landing
