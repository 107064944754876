import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Landing from './modules/layout/Landing'

function App() {
  return (
    <BrowserRouter>
      <Landing />
    </BrowserRouter>
  );
}

export default App;
