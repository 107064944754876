import React from 'react'
import { Link } from 'react-router-dom'
import StandardLanding from '../../../shared/components/StandardLanding'
import ListPartner from '../components/ListPartner'

const DepedPartners = () => {
    return (
        <StandardLanding>
            <div className="flex flex-col items-center">
                <div className="main-banner flex flex-col items-center justify-center text-white w-full">
                    <p className="font-bold text-4xl mb-5">LIST OF DEPED PARTNERS</p>
                    <div className="flex font-bold">
                        <Link to="/">Home</Link>
                        <span className="mx-5">|</span>
                        <Link to="/about-overview">DepEd Partners</Link>
                    </div>
                </div>
                <div className="ct-container w-full mt-20">
                    <ListPartner />
                </div>
            </div>
        </StandardLanding>
    )
}

export default DepedPartners
