import React from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import LoginCard from '../components/LoginCard'


function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
}

const AboutOverView = () => {
    return (
        <div>
            <div className="main-banner flex flex-col items-center justify-center text-white">
            </div>
            <div className="ct-container flex flex-center justify-center mb-20 login-box" style={{ marginTop: '-200px'}}>
                <LoginCard />
            </div>
        </div>
    )
}

export default AboutOverView
