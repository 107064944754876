import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import FormWrapper from "../../../shared/components/FormWrapper";
import image from "../../../shared/images/Assets_DepEd-DPDS/Title.png";

const FormContactUs = () => {
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [contactFields] = Form.useForm();
  const [alertApi, contextHolder] = message.useMessage();

  const handleOnFinish = async (input) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/website/contact-us`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    };

    setLoading(true);
    const response = await fetch(apiUrl, options);
    const data = await response.json();

    if (data?.success) {
      setLoading(false);
      contactFields.resetFields();
      alertApi.info(
        "Successfully sent inquiry to partnershipportal@deped.gov.ph",
        3
      );
    }
  };

  return (
    <div className="w-1/2 mr-5 col">
      {contextHolder}
      <div>
        <img src={image} />
        <p className="absolute ml-5 font-bold text-2xl -mt-2 uppercase">
          contact us
        </p>
      </div>
      <FormWrapper>
        <Form onFinish={handleOnFinish} form={contactFields}>
          <div className="mt-10 flex">
            <div className="w-1/2 mr-7">
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: "Please input your name!" },
                  { max: 100 },
                ]}
              >
                <Input placeholder="*Your Name" className="h-14" />
              </Form.Item>
              <Form.Item
                name="contact_number"
                rules={[
                  {
                    required: true,
                    message: "Please input your contact number!",
                  },
                  { len: 11 },
                ]}
              >
                <Input placeholder="*Your Contact Number" className="h-14" />
              </Form.Item>
            </div>
            <div className="w-1/2 ml-5">
              <Form.Item
                name="email_address"
                rules={[
                  {
                    required: true,
                    message: "Please input your email address!",
                  },
                  { type: "email" },
                ]}
              >
                <Input placeholder="*Your Email Address" className="h-14" />
              </Form.Item>
              <Form.Item
                name="subject"
                rules={[
                  { required: true, message: "Please input your subject!" },
                  { max: 50 },
                ]}
              >
                <Input placeholder="*Your Subject" className="h-14" />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="message"
            rules={[{ required: true, message: "Please input your messaage!" }]}
          >
            <TextArea placeholder="*Your Message here" rows={15} />
          </Form.Item>
          <Button
            htmlType="submit"
            className="mt-10 bg-blue-10 text-white w-36 h-10 rounded-md"
            loading={loading}
          >
            SEND NOW
          </Button>
          <p
            className="pt-3 text-blue-600"
            style={{
              display: !loading && "none",
            }}
          >
            Sending inquiry. Please wait...
          </p>
        </Form>
      </FormWrapper>
    </div>
  );
};

export default FormContactUs;
