import React, { useState, useEffect } from 'react'
import SubHeader from '../components/SubHeader'
import logo from '../../../shared/images/logo.png'
import logo1 from '../../../shared/images/Assets_DepEd-DPDS/Transparency-seal-circle-300x300.png'

const PageHeader = () => {
    const [show, setShow] = useState(true)

    const controlNavbar = () => {
        if(window.scrollY){
            setShow(false)
        } else {
            setShow(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return() => {
            window.removeEventListener('scroll', controlNavbar)
        }
    }, [])

    return (
        <div className="z-50 sticky top-0">
            <SubHeader />
        </div>
    )
}

export default PageHeader
