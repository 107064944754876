import React from "react";
import { Button, Collapse } from "antd";
import { RightCircleFilled } from "@ant-design/icons";
import image from "../../../shared/images/Assets_DepEd-DPDS/Title.png";
import {
  ABSCBN,
  ALASKA,
  AMA,
  AUB,
  AYALA,
  BANKO_SENTRAL_NG_PILIPINAS,
  CEBUANA,
  CENTURY_PACIFIC_FOOD,
  CIGNAL,
  CITICORE_HOLDING_INVESTMENT,
  CONVERGE,
  EDUCO,
  ENERGY_CORP_1590,
  FIRST_NATGAS,
  GAKKEN_PH,
  GLOBE_TELCO,
  GMA,
  GMA_AFFORDABOX,
  IBCTV,
  ICM,
  JICA,
  KOSMOS_MOBILE,
  MANILA_WATER,
  MARIWASA,
  MAYNILAD_FOUNDATION,
  MCDO,
  MEGA_CONSTRUCTION_CORP,
  MICROSOFT,
  MILO,
  MOVING_BRIDGE_FOUNDATION,
  NATASHA_GOULBOURN_FOUNDATION,
  NATIONAL_BOOKSTORE,
  NATIONAL_GRID_CORP,
  NESTLE,
  COLGATE_PALMOLIVE,
  PERKINSPIRED,
  PETRON_CORP,
  PHILSTAR,
  PLDT,
  PNVSCA,
  QUEZON_CITY,
  QUIPPER,
  RCBC,
  RESEARCH_CENTER_FOR_TEACHER_QUALITY,
  RTVM,
  SAVE_THE_CHILDREN,
  SCG_MARKETING_PH,
  SEAMEO_INNOTECH,
  SEARCA,
  SKY_CABLE,
  SMART,
  SOLAR_LEARNING,
  UAW,
  UNILEVER,
  USAID,
  FIRST_GEN,
  GABAYGURO
} from "./constants/PartnersData";

const ListPartner = () => {
  const { Panel } = Collapse;

  const text = `
    A dog is a type of domesticated animal.
    Known for its loyalty and faithfulness,
    it can be found as a welcome guest in many households across the world.
    `;

  const data = {
    2021: [
      NESTLE,
      UNILEVER,
      ABSCBN,
      MICROSOFT,
      USAID,
      SMART,
      JICA,
      MANILA_WATER,
      COLGATE_PALMOLIVE,
      PLDT,
      EDUCO,
      MILO,
      ENERGY_CORP_1590,
      MOVING_BRIDGE_FOUNDATION,
      NATIONAL_BOOKSTORE,
    ],
    2020: [
      GLOBE_TELCO,
      PLDT,
      ICM,
      SEAMEO_INNOTECH,
      RTVM,
      SOLAR_LEARNING,
      IBCTV,
      GMA_AFFORDABOX,
      SKY_CABLE,
      CONVERGE,
      CIGNAL,
      SAVE_THE_CHILDREN,
      UAW,
      GMA,
      MCDO,
    ],
    2019: [
      CENTURY_PACIFIC_FOOD,
      MCDO,
      GAKKEN_PH,
      GMA,
      QUEZON_CITY,
      SEARCA,
      CEBUANA,
      FIRST_NATGAS,
      KOSMOS_MOBILE,
      MARIWASA,
      SCG_MARKETING_PH,
      PNVSCA,
      FIRST_GEN,
      GABAYGURO
    ],
    2018: [
      AYALA,
      MARIWASA,
      NATIONAL_GRID_CORP,
      PERKINSPIRED,
      PETRON_CORP,
      BANKO_SENTRAL_NG_PILIPINAS,
    ],
    2017: [ALASKA, NATASHA_GOULBOURN_FOUNDATION, AMA],
    2016: [
      QUIPPER,
      CITICORE_HOLDING_INVESTMENT,
      MEGA_CONSTRUCTION_CORP,
      RESEARCH_CENTER_FOR_TEACHER_QUALITY,
    ],
    2015: [PHILSTAR, AUB, RCBC, CEBUANA],
    2014: [MAYNILAD_FOUNDATION],
    2008: [COLGATE_PALMOLIVE],
    2003: [MANILA_WATER],
  };

  function flattenAndArrangeAlphabetically(partnersData) {
    const newList = [];
    Object.values(partnersData).forEach((data) => {
      data.forEach((item) => {
        if (!newList.some((list) => list.name === item.name)) {
          newList.push(item);
        }
      });
    });
    return newList.sort((a, b) => a.name.localeCompare(b.name));
  }
  const arrangedData = flattenAndArrangeAlphabetically(data);
  return (
    <div className="md:mx-24">
      {arrangedData.map((partnerData) => {
        return !partnerData.logo ? null : (
          <div className="my-8 flex items-center justify-between">
            <div className="flex gap-3">
              <div className="w-32">
                <img src={partnerData.logo} className="mr-5 w-max" />
              </div>
              <p style={{ margin: 'auto' }} className="font-bold">{partnerData.name}</p>
            </div>
            <Button
              className="text-white rounded-md h-10 py-0 flex items-center"
              target="_blank"
              style={{ background: '#0038A8', color: 'white !important' }}
              href={partnerData.link}
            >
              View Website
            </Button>
          </div>
        );
      })}
      {/* <div className="my-10 flex justify-center">
        <Button className="bg-blue-10 text-white h-10 w-32">Load More</Button>
      </div> */}
    </div>
  );
};

export default ListPartner;
