import React, { Component } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "100%",
  position: "static",
};

const baseUrl = process.env.REACT_APP_BASE_URL;

export class MapContainer extends Component {
  render() {
    return (
      <div className="relative">
        <div className="contact-details text-white p-6">
          <div className="flex mb-6">
            <img src="/icons/map-regular.svg" width="20" className="mr-3" />
            <span style={{ lineHeight: "normal" }} className="text-md">
              Meralco Ave., Pasig, 1605 Metro Manila
            </span>
          </div>
          <div className="flex mb-6">
            <img
              src="/icons/phone_white_24dp.svg"
              width="20"
              className="mr-3"
            />
            <span style={{ lineHeight: "normal" }} className="text-md">
              (02) 8633 7228
            </span>
          </div>
          <div className="flex mb-6">
            <img
              src="/icons/envelope-regular.svg"
              width="20"
              className="mr-3"
            />
            <span style={{ lineHeight: "normal" }} className="text-md">
              partnershipportal@deped.gov.ph
            </span>
          </div>
          <div className="flex mb-6">
            <img
              src="/icons/language_white_24dp.svg"
              width="20"
              className="mr-3"
            />
            <span style={{ lineHeight: "normal" }} className="text-md">
              {baseUrl}
            </span>
          </div>
        </div>
        <Map
          google={this.props.google}
          zoom={14}
          style={{ height: "650px", width: "100%", position: "static" }}
          initialCenter={{
            lat: 14.5870384,
            lng: 121.0627921,
          }}
        ></Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCz3fHJKJjN_m92jbrFDJkTcguVSE0AguE",
})(MapContainer);
