import React from "react";
import styled from "@emotion/styled";

function CardWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default CardWrapper;

const StyledDiv = styled.div({
  ".ant-card": {
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    borderRadius: ".8rem",
  },
  ".ant-card-body": {
    padding: "0px !important",
  },
  ".card": {
    ".card-img": {
      objectFit: "cover",
      objectPosition: "center",
    },
    ".card-content": {
      padding: "20px",

      ".card-title": {
        color: "#363636",
        fontSize: ".8rem;",
        lineHeight: "1.5rem;",
        fontWeight: "600",
        textTransform: "capitalize",
      },

      ".lg-title": {
        color: "#c96a5e ",
        fontSize: "2rem;",
        lineHeight: "1rem;",
        fontWeight: "500",
        textTransform: "capitalize",
      },

      ".md-title": {
        color: "#c96a5e ",
        fontSize: "1.2rem;",
        lineHeight: "1.5rem;",
        fontWeight: "500",
        textTransform: "capitalize",
      },

      ".sm-title": {
        color: "#c96a5e ",
        fontSize: "12px;",
        lineHeight: ".9rem;",
        fontWeight: "600",
        textTransform: "capitalize",
      },

      ".card-subtitle": {
        fontSize: "12px;",
        lineHeight: "1.5rem;",
        fontWeight: "400",
      },
    },
  },
});
