import React from "react";
import Slider from "../components/SliderFlick";

import Image1 from "../../../shared/assets/images/image1.png";
import Image2 from "../../../shared/assets/images/image2.png";
import Image3 from "../../../shared/assets/images/image2.png";
import Image4 from "../../../shared/assets/images/image2.png";

import dpapBanner1 from "../../../shared/assets/images/banner/banner_1.jpg";
import dpapBanner2 from "../../../shared/assets/images/banner/banner_2.jpg";
import dpapBanner3 from "../../../shared/assets/images/banner/banner_3.jpg";
import dpapBanner4 from "../../../shared/assets/images/banner/banner_4.jpg";
import dpapBanner5 from "../../../shared/assets/images/banner/banner_5.jpg";
import dpapBanner6 from "../../../shared/assets/images/banner/banner_6.jpg";
import dpapBanner7 from "../../../shared/assets/images/banner/banner_7.jpg";
import dpapBanner8 from "../../../shared/assets/images/banner/banner_8.jpg";

// const images = [Image1, Image2, Image3, Image4];
const images = [
  dpapBanner1,
  dpapBanner2,
  dpapBanner3,
  dpapBanner4,
  dpapBanner5,
  dpapBanner6,
  dpapBanner7,
  dpapBanner8,
];

const Banner = () => {
  const backOfficeUrl = process.env.REACT_APP_BACK_OFFICE_BASE_URL;

  return (
    <div>
      <div className="bannger-page">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 slider-main flex-wrap">
          <div className="col description">
            <h3>
              DepEd Partnership <br /> Assistance Portal{" "}
            </h3>
            <p>
              The DepEd Partnership Assistance Portal (DPAP) is a user-friendly
              system designed to contribute to the achievement of educational
              goals by supporting partnership building, automating partnership
              management processes and implementing knowledge management
              strategies to facilitate evidence-based decision making.
            </p>

            <span className="flex items-center">
              <img
                src="/icons/chevron-circle-down-solid.svg"
                width="30"
                className="mr-2"
              />
              Scroll Down to Learn More
            </span>
          </div>
          <div className="col flex justify-center">
            <div className="slider-container">
              <Slider
                options={{
                  autoPlay: 4000,
                  pauseAutoPlayOnHover: true,
                  wrapAround: true,
                  fullscreen: true,
                  adaptiveHeight: true,
                  prevNextButtons: false,
                }}
              >
                {images.map((image, index) => (
                  <div
                    className="slider-images"
                    style={{
                      backgroundImage: `url('${image}')`,
                      backgroundSize: "cover",
                    }}
                    key={index}
                  >
                    <div className="slider-content">
                      {/*  <div className="text">
                        <h5 style={{marginBottom: 10}}>DepEd Partnership Assistance Portal</h5>
                        <p>
                          The DepEd Partnership Assistance Portal (DPAP) is a user-friendly system designed to contribute to the achievement of educational goals by supporting partnership building, automating partnership management processes and implementing knowledge management strategies to facilitate evidence-based decision making.
                        </p>
                      </div> */}
                      {/* revisit!: refactor this button onclick event by using the react router */}
                      <a
                        href={backOfficeUrl}
                        target="_blank"
                        className="bg-gray-50 hover:bg-gray-300 text-blue-600 font-bold py-2 px-4 rounded align-self-center banner-btn"
                        rel="noreferrer"
                      >
                        Register as Partner
                      </a>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
