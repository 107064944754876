import React from 'react'
import { Link } from 'react-router-dom'
import FormContactUs from '../components/FormContactUs'
import StandardLanding from '../../../shared/components/StandardLanding'
import Map from '../components/Map'

const Landing = () => {
    return (
        <StandardLanding>
            <div className="">
                <div className="main-banner flex flex-col items-center justify-center text-white w-full">
                    <p className="font-bold text-4xl mb-5">CONTACT US</p>
                    <div className="flex font-bold">
                        <Link to="/">Home</Link>
                        <span className="mx-5">|</span>
                        <Link to="/contact-us">Contact Us</Link>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex contact_map mt-20 mb-20">
                    <FormContactUs />
                    <div className="w-1/2 col">
                        <Map />
                    </div>
                </div>
            </div>
        </StandardLanding>
    )
}

export default Landing
