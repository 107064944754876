import React from 'react'
import PageHeader from './containers/PageHeader'
import PageContent from './containers/PageContent'
import PageFooter from './containers/PageFooter'

const Landing = () => {
    // Miguel: !revisit - Landing > Banner > SliderFlick.js
    // having conflicting issues during react-routing navigation

    return (
        <div>
            <PageHeader />
            <PageContent />
            <PageFooter />
        </div>
    )
}

export default Landing
