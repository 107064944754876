import React from 'react';
import { Bar } from 'react-chartjs-2';
import image from '../../../shared/images/Assets_DepEd-DPDS/Title.png';

const Chart = () => {
  const vertical = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
    scales: {
      y: {
        display: true,
        grid: {
          color: '#cccccc',
        },
      },
      x: {
        display: false,
      },
    },
  };

  const horizontal = {
    indexAxis: 'y',
    scales: {
      y: {
        display: false,
        grid: {
          color: '#cccccc',
        },
      },
      x: {
        display: true,
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
  };

  const year = [
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
  ];
  const data = {
    labels: year,
    datasets: [
      {
        label: '',
        data: [10, 20, 30, 40, 50, 60, 70, 80],
        fill: true,
        borderColor: ['rgba(255, 206, 86, 0.2)'],
        backgroundColor: '#cd1f3098',
        pointBorderColor: 'rgba(255, 206, 86, 0.2)',
        pointBackgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderWidth: 0.5,
        tension: 0.7,
      },
      // {
      //     label: `Completed for ${moment().format("YYYY")}`,
      //     data: [1,2,3,5,3,4,2],
      //     fill: true,
      //     borderColor: ['rgba(54, 162, 235, 0.2)'],
      //     backgroundColor: ['rgba(54, 162, 235, 0.2)'],
      //     pointBorderColor: 'rgba(54, 162, 235, 0.2)',
      //     pointBackgroundColor: 'rgba(54, 162, 235, 0.2)',
      //     borderWidth: .5,
      //     tension: .7
      // }
    ],
  };

  return (
    <div className="ct-container py-10 chart-col flex">
      <div className="col">
        <div className="chart-card mt-10 shadow-2xl p-2 rounded-md">
          <div className="p-4">
            <div style={{ height: '50px', width: '100%' }}>
              <Bar
                data={data}
                options={vertical}
                className=""
                containerProps={{ style: { height: '300px' } }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <img src={image} />
        <p className="absolute ml-5 text-2xl -mt-2 font-bold">
          NUMBER OF SCHOOLS BY REGION
        </p>

        <div className="mt-10 p-4">
          <p className="mb-10">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>
      <div className="col">
        <img src={image} />
        <p className="absolute ml-5 text-2xl -mt-2 font-bold">
          NUMBER OF PATNERS BY REGION
        </p>
        <div className="chart-card mt-10 shadow-2xl p-2 rounded-md">
          <div className="p-4">
            <div style={{ height: '50px', width: '100%' }}>
              <Bar
                data={data}
                options={vertical}
                className=""
                containerProps={{ style: { height: '300px' } }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="mt-10 p-4">
          <p className="mb-10">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p className="mb-10">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>

      <div className="col">
        <div className="mt-10 p-4">
          <p className="mb-10">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p className="mb-10">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>

      <div className="col">
        <img src={image} />
        <p className="absolute ml-5 text-2xl -mt-2 font-bold">
          DEPED PARTNERS BY YEARS
        </p>
        <div className="chart-card mt-10 shadow-2xl p-2 rounded-md">
          <div className="p-4">
            <div style={{ height: '50px', width: '100%' }}>
              <Bar
                data={data}
                options={horizontal}
                className=""
                containerProps={{ style: { height: '300px' } }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chart;
