import NestleLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Nestle.png";
import GabayGuro from "../../../../shared/images/Assets_DepEd-DPDS/Partners/GabayGuro.png";
import ABSCBNLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/ABS-CBN.png";
import Unilever from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Unilever.png";
import MicrosoftLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/microsoft.png";
import GlobeLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Globe.png";
import PLDTLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/PLDT-new.png";
import ICMLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/ICM.png";
import SeameoInnotechLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Seameo-Innotech.png";
import RTVMLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/RTVM.png";
import SolarLearningLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Solar-Learning.png";
import IBCTVLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/IBC-13_2011_logo.png";
import GMAAffordaboxLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/GMA-Affordabox.png";
import SkyCableLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Sky-Cable.png";
import ConvergeLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Converge.png";
import CignalLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Cignal.png";
import SaveTheChildrenLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Save-The-Children.png";
import UAWLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/UAW.png";
import AlaskaLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Alaska.png";
import PhilStarLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/The-Philippine-Star.png";
import AUBLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/AUB.png";
import CebuanaLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/cebuana.JPG";
import JICALogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/JICA.png";
import FirstGenLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/FirstGen.JPG";
import GakkenPHLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Gakken-Philippines-Logo.png";
import MegaConstructionCorpLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Megawide-Construction-Corporation.png";
import SmartLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/smart.png";
import ManilaWaterLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Manila-Water.png";
import MariwasaLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Mariwasa.JPG";
import MCDOLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/mcdonalds.png";
import INOVALogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/iNOVA.png";
import ColgatePalmolivePHLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/colgate-palmolive-logo.png";
import QuezonCityLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Quezon-City.png";
import EducoLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/Educo.png";
import MiloLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/milo.png";
import AMALogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/AMA-Education-System.png";
import USAIDLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/USAID.png";
import RCBCLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/RCBC.png";
import GMALogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/GMA-NETWORK.png";
import PNVSCALogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/pnvsca.JPG";
import RTCQLogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/RCTQ.JPG";
import SEARCALogo from "../../../../shared/images/Assets_DepEd-DPDS/Partners/searca-logo.png";

export const NESTLE = {
  logo: NestleLogo,
  name: "NESTLE",
  link: "https://www.nestle.com.ph/",
};

export const GABAYGURO = {
  logo: GabayGuro,
  name: "Gabay Guro",
  link: "https://www.gabayguro.com/",
};

export const UNILEVER = {
  logo: Unilever,
  name: "Unilever",
  link: "https://www.unilever.com.ph/",
};

export const ABSCBN = {
  logo: ABSCBNLogo,
  name: "ABS-CBN",
  link: "https://news.abs-cbn.com/",
};

export const MICROSOFT = {
  logo: MicrosoftLogo,
  name: "Microsoft Philippines",
  link: "https://www.microsoft.com/en-ph",
};

export const USAID = {
  logo: USAIDLogo,
  name: "UNITED STATE AGENCY FOR INTERNAL DEVELOPMENT",
  link: "https://usaid.gov/",
};

export const SMART = {
  logo: SmartLogo,
  name: "SMART COMMUNICATION, INC.",
  link: "https://smart.com.ph/",
};

export const JICA = {
  logo: JICALogo,
  name: "JICA PHILIPPINES",
  link: "https://www.jica.go.jp/",
};

export const MANILA_WATER = {
  logo: ManilaWaterLogo,
  name: "Manila Water Foundation",
  link: "https://manilawaterfoundation.org/",
};

export const COLGATE_PALMOLIVE = {
  logo: ColgatePalmolivePHLogo,
  name: "Colgate-Palmovile Philippines, Inc.",
  link: "https://www.colgatepalmolive.ph/",
};

export const PLDT = {
  logo: PLDTLogo,
  name: "PLDT",
  link: "https://pldthome.com/",
};

export const EDUCO = {
  logo: EducoLogo,
  name: "Educo Philippines",
  link: "https://www.educo.org.ph/",
};

export const MILO = {
  logo: MiloLogo,
  name: "Milo",
  link: "https://www.milo.com.ph/",
};

export const ENERGY_CORP_1590 = {
  logo: "",
  name: "1590 energy corporation",
  link: "",
};

export const MOVING_BRIDGE_FOUNDATION = {
  logo: "",
  name: "Moving Bridge Foundation",
  link: "https://gogetfunding.com/",
};

export const NATIONAL_BOOKSTORE = {
  logo: "",
  name: "National Bookstore Foundation",
  link: "https://www.nationalbookstore.com/",
};

export const GLOBE_TELCO = {
  logo: GlobeLogo,
  name: "GLOBE TELCO.",
  link: "https://www.globe.com.ph/",
};

export const ICM = {
  logo: ICMLogo,
  name: "International Care Ministries",
  link: "https://www.caremin.com/2020/12/departmentofeducationpartnership",
};

export const SEAMEO_INNOTECH = {
  logo: SeameoInnotechLogo,
  name: "Seameo Tech",
  link: "https://www.seameo-innotech.org/",
};

export const RTVM = {
  logo: RTVMLogo,
  name: "RTVM",
  link: "https://rtvm.gov.ph/",
};

export const SOLAR_LEARNING = {
  logo: SolarLearningLogo,
  name: "Solar Learning",
  link: "https://solarlearning.ph/",
};

export const IBCTV = {
  logo: IBCTVLogo,
  name: "IBC TV 13",
  link: "https://m.facebook.com/ibctv13",
};

export const GMA_AFFORDABOX = {
  logo: GMAAffordaboxLogo,
  name: "GMA Affordabox",
  link: "https://www.gmanetwork.com/affordabox",
};

export const SKY_CABLE = {
  logo: SkyCableLogo,
  name: "Sky Cable",
  link: "https://www.mysky.com.ph/metromanila",
};

export const CONVERGE = {
  logo: ConvergeLogo,
  name: "Converge",
  link: "https://www.convergeict.com/",
};

export const CIGNAL = {
  logo: CignalLogo,
  name: "Cignal Digital TV",
  link: "https://cignaltv.ph/",
};

export const SAVE_THE_CHILDREN = {
  logo: SaveTheChildrenLogo,
  name: "Save The Children",
  link: "https://www.savethechildren.org.ph/",
};

export const UAW = {
  logo: UAWLogo,
  name: "Union Label Committee",
  link: "https://uaw.org/",
};

export const GMA = {
  logo: GMALogo,
  name: "GMA Network",
  link: "https://www.gmanetwork.com/",
};

export const MCDO = {
  logo: MCDOLogo,
  name: "MCDonald's",
  link: "https://mcdonalds.com.ph/",
};

export const CENTURY_PACIFIC_FOOD = {
  logo: "",
  name: "Century Pacific Food, Inc.",
  link: "https://centurypacific.com.ph/",
};

export const GAKKEN_PH = {
  logo: GakkenPHLogo,
  name: "Gakken (Philippines), INC.",
  link: "https://gakkenphil.com/",
};

export const QUEZON_CITY = {
  logo: QuezonCityLogo,
  name: "Quezon City, City in the Philippines",
  link: "https://quezoncity.gov.ph/",
};

export const SEARCA = {
  logo: SEARCALogo,
  name: "Southeast Asian Regional Center for Graduate Study and Research in Agriculture (SEARCA) - Specialist Institution",
  link: "https://www.searca.org/",
};

export const CEBUANA = {
  logo: CebuanaLogo,
  name: "Cebuana Lhuillier",
  link: "https://www.cebuanalhuillier.com/",
};

export const FIRST_NATGAS = {
  logo: "",
  name: "First NatGas Power Corporation",
  link: "https://www.firstgen.com.ph/",
};

export const KOSMOS_MOBILE = {
  logo: "",
  name: "Kosmos Technomobile, Inc.",
  link: "",
};

export const MARIWASA = {
  logo: MariwasaLogo,
  name: "Mariwasa Siam Ceramics Inc",
  link: "https://www.mariwasa.com/",
};

export const SCG_MARKETING_PH = {
  logo: "",
  name: "SCG Marketing Philippines Inc.",
  link: "https://b-m.facebook.com/SCG-Marketing-Philippines-Inc-130506147077102/photos/",
};

export const PNVSCA = {
  logo: PNVSCALogo,
  name: "Philippines National Volunteer Service Coordinating Agency",
  link: "https://www.pnvsca.gov.ph/",
};

export const AYALA = {
  logo: "",
  name: "Ayala Foundation",
  link: "https://www.ayalafoundation.org/",
};

export const NATIONAL_GRID_CORP = {
  logo: "",
  name: "National Grid Corporation of the Philippines",
  link: "https://www.ngcp.ph/",
};

export const PERKINSPIRED = {
  logo: "",
  name: "Perkinspired",
  link: "",
};

export const PETRON_CORP = {
  logo: "",
  name: "Petron Corporation",
  link: "https://www.petron.com/",
};

export const BANKO_SENTRAL_NG_PILIPINAS = {
  logo: "",
  name: "Banko Sentral ng Pilipinas",
  link: "https://www.bsp.gov.ph/SitePages/Default.aspx",
};

export const ALASKA = {
  logo: AlaskaLogo,
  name: "Alaska Milk Corp.",
  link: "https://www.alaskamilk.com/purpose/programs/",
};

export const NATASHA_GOULBOURN_FOUNDATION = {
  logo: "",
  name: "Natasha Goulbourn Foundation",
  link: "https://www.facebook.com/NGFMindstrong/",
};

export const AMA = {
  logo: AMALogo,
  name: "AMA Education System",
  link: "https://www.amaes.edu.ph/",
};

export const QUIPPER = {
  logo: "",
  name: "Quipper Ltd.",
  link: "https://www.quipper.com/ph/",
};

export const CITICORE_HOLDING_INVESTMENT = {
  logo: "",
  name: "Citicore Holding Investment Inc.",
  link: "https://www.phcc.gov.ph/",
};

export const MEGA_CONSTRUCTION_CORP = {
  logo: MegaConstructionCorpLogo,
  name: "Megawide Contruction Corp.",
  link: "http://megawide.com.ph/",
};

export const RESEARCH_CENTER_FOR_TEACHER_QUALITY = {
  logo: RTCQLogo,
  name: "Research Center for Teacher Quality",
  link: "https://www.rctq.ph/",
};

export const PHILSTAR = {
  logo: PhilStarLogo,
  name: "philStar",
  link: "https://www.philstar.com/",
};

export const AUB = {
  logo: AUBLogo,
  name: "Asia United Bank",
  link: "https://www.aub.com.ph/",
};

export const RCBC = {
  logo: RCBCLogo,
  name: "RCBC",
  link: "https://www.rcbc.com/",
};

export const MAYNILAD_FOUNDATION = {
  logo: "",
  name: "Maynilad Foundation",
  link: "https://www.mayniladwater.com.ph/",
};

export const FIRST_GEN = {
  logo: FirstGenLogo,
  name: "First Gen Hydro Power Corp.",
  link: "https://www.firstgen.com.ph/",
};
