import React from "react";
import { Image } from "antd";
import CardWrapper from "../../../shared/components/CardWrapper";
import image from "../../../shared/images/Assets_DepEd-DPDS/Article 1.png";
import article_3_placeholder from "../../../shared/assets/images/article_3_srcdoc_placeholder.png";
// import { articles } from '../constants'

import photoCover1 from "../../../shared/assets/images/articles/article_1.a.png";
import photoCover2 from "../../../shared/assets/images/articles/article_1.b.png";
import photoCover3 from "../../../shared/assets/images/articles/article_2.png";
import photoCover33 from "../../../shared/assets/images/articles/article_3.jpg";
import photoCover4 from "../../../shared/assets/images/articles/article_4.jpg";
import photoCover5 from "../../../shared/assets/images/articles/article_5.jpg";
import photoCover6 from "../../../shared/assets/images/articles/article_6.jpg";

export const articles = [
  {
    title:
      "GSIS donates P10 Million worth of technology assistance packages to 25 public schools",
    id: "1",
    contentP1:
      "The Government Service Insurance System (GSIS), through its Corporate Social Advocacies and Public Relations Facilities Department, turns over technology assistance packages with estimated worth of P400,000.00 to each 25 public schools nationwide, totalling to around P10 million peso-worth of donations as part of the Adopt-a-School Program of the Department of the Education. These packages include laptops, LCD projectors, 50-inch smart TVs, trolley speakers, printers and external drives, which are intended to support the continuous provision of quality education to learners.",
    contentP2:
      "The Department of Education (DepEd) has been partnering with the GSIS, a social insurance institution established through Republic Act (R.A.) No. 8291,known as GSIS Act of 1997, on various programs and projects for education. Since 2017, it has approved the adoption of selected schools as beneficiaries of its Corporate Social Responsibiliy (CSR) program. In 2020, GSIS provided P200,000 worth of assistance to each selected public school in the low-income municipalities, while in 2021, it provided P300,000 worth of assistance each.",
    contentP3:
      "This year, the selected beneficiary schools are Pinayag National High School, Nueva Vizcaya; Maguyepyep Integrated School, Abra; Calawaan Elementary School, Ilocos Sur; Baccuit Elementary School, Cagayan; Celestino Diaz Elementary School, Capiz; San Miguel Echague, Isabela; Bagumbayan Elementary School, Catanduanes; Nahawan Elementary School, Bohol; Don Ruben E. Ecleo Sr. Memorial National High School, DInagat Island; Surigao City National High School, Surigao City; Camp Edward Elementary School, Surigao del Norte; Gaas Elementary School, Sorsogon; Calpi Elementary School, Sorsogon; Aroroy Central School, Masbate; Hinolaso National High School- Caglao-an Annex, Eastern Samar; Catig Primary School, Southern Leyte; Tagaytay Elementary School, Albay; Magallanes Elementary School, Davao City; Manjuyod Central Elementary School, Negros Oriental; Sinoropan National High School, Zamboanga City; and Talisay Integrated School, Dapitan City.",
    contentP4:
      "The donation turn-over and ceremonial signing of the Memorandum of Agreement with the selected school runs from February to April 2023, with the assistance of the External Partnerships Service.",
    photoCover1,
    photoCover2,
  },
  {
    title: "DepEd conducts Partnership Appreciation Program 2022",
    id: "2",
    contentP1:
      "The Department of Education, through its External Partnerships Service, conducted the Partnership Appreciation Program for its partners from the private and public sector on December 13, 2022 at the Wack-wack Golf and Country Club, Mandaluyong City, Metro Manila.",
    contentP2:
      "Around 200 partners attended the said event, with Vice President and DepEd Secretary Sara Z. Duterte as the keynote speaker. In her speech, Vice President Duterte remarked, “Our partners have helped us build school buildings, repair and refurbish classrooms during Brigada Eskwela, after calamities. You have also helped us train our teachers in reading, English, Science, Math and ICT. Some of you have also provided teaching and learning materials and worked directly with our schools and divisions for community-based interventions such as school feeding or volunteer man hours. When we needed to shift abruptly to distance learning during the pandemic, your assistance and support packages were instrumental in the basic education learning continuity plan. And for your continuing support, we are truly grateful.”",
    contentP3:
      "Local, national, including other government agencies and international partners were recognized and awarded with plaques of appreciation during the said event.",
    contentP4:
      "The program also highlighted the pre-launching of the DepEd Partnership Assistance Portal. Vice-President Duterte emphasized that DepEd realized that it needs a one-stop shop that existing partners and organizations who wish to support the education of children and youth can visit at any point. She further  announced that the portal will be officially launched in 2023.",
    photoCover1: photoCover3,
  },
  {
    title: "DepEd launches online portal to strengthen partner-school engagement",
    id: "3",
    contentP1:
      "PASIG CITY, March 23, 2023 — The Department of Education (DepEd), in collaboration with PLDT-Gabay Guro and Metro Pacific Investment Corporation, launched on Thursday the DepEd Partnership Assistance Portal (DPAP) to strengthen stakeholder engagement in schools.",
    contentP2:
      "“Today marks a significant first step in building better relations between our partners and schools. We hope that through this portal, we will be able to attract more civic-minded partners in the future,” Assistant Secretary for Administration Christopher Lawrence S. Arnuco said.",
    contentP3:
      "Spearheaded by the External Partnership Services (EPS), the program launched the portal that aims to bridge gaps between companies or organizations who are willing to adopt a school and supply them with their needs.",
    contentP4:
      "DPAP is also aligned with the MATATAG Agenda’s digitization program which includes improved partnership processes, accuracy, and transparency of partnership data that can be easily retrieved and accessed by DepEd offices, partners, and stakeholders.",
    contentP5:
      "“The DPAP is the result of the hardwork of experts, and of course, the hardworking team who enthusiastically collaborated in a bid to advance education partnerships for DepEd,” EPS Director Margarita Consolacion Ballesteros stated.",  
    contentP6:
      "Through the system’s features, schools can create their account, post their School Improvement Plan (SIP), and tap partners who seeks to help as part of their Corporate Social Responsibility (CSR).",  
    contentP7:
      "The system highlights several digitized features like the e-appointment that automates appointment setting for both partners and schools, the menu of investment that lists all of the required assistance of schools that partners can fulfill, and the partnership map that showcase the partnerships made across the country.",  
    contentP8:
      "The launch was attended by Regional Education Support Services Division (ESSD) Chiefs and Regional Partnerships Focal Persons, government agencies, private organizations, and international and development partners, and corporate partners.",  
    contentP9:
      "Additionally, the DPAP public site will feature stories and articles about partnership triumphs in efforts of acquiring more partners in the future. Meanwhile, EPS revealed that this is only the first phase of the program, and the Service will continue to update the portal.",  
      photoCover33: photoCover33,
  },
  {
    title: "DepEd convenes ODA partners to strengthen MATATAG Agenda interventions",
    id: "4",
    contentP1:
      "PASIG CITY, April 19, 2023 — The Department of Education (DepEd) gathered representatives of the Official Development Assistance (ODA) Partners on Thursday to discuss existing and future support for the MATATAG Agenda.",
    contentP2:
      "“[Our ODA partners] have tirelessly committed to the DepEd’s mission of promoting every Filipinos right to quality basic education. Let us continue to forge genuine partnerships and pursue new projects and programs for the advancement of basic education in the Philippines,” Undersecretary for Finance Annalyn Sevilla said.",
    contentP3:
      "The meeting was conducted to align the partner programs to the MATATAG Agenda, provide insights and suggestions for regions that may need more programs, and identify the key areas into which partners can provide their expertise and assistance.",
    contentP4:
      "Currently, ODA Partners are facilitating a total of 13 ongoing and pipeline projects in the country. These ODA initiatives are administered by partners to promote sustainable social and economic development and welfare of the Philippines in accordance with the Republic Act No. 8182 (ODA Act of 1996).",
    contentP5:
      "The meeting is also part of the series of preliminary activities the Department has organized in preparation for the Partners Forum to be held on May 25, 2023.",  
    contentP6:
      "The convergence was attended by the Official Development Assistance (ODA) Partners, which includes Asian Development Bank (ADB), Asian Infrastructure Investment Bank (AIIB), Embassy of the People’s Republic of China in the Republic of the Philippines, Government of Australia-Department of Foreign Affairs and Trade (GoA-DFAT), and Japan International Cooperation Agency (JICA).",  
    contentP7:
      "Korea Eximbank Resident Mission, Korea International Cooperation Agency (KOICA), United Nations Children’s Fund (UNICEF), United States Agency for International Development (USAID), World Bank (WB), and Philippine Business for Education (PBEd) also attended the said meeting.",  
    photoCover4: photoCover4,
  },
  {
    title: "Mensahe ni Vice President and Education Secretary Sara Z. Duterte sa paglulunsad ng DepEd Partnership Assistance Portal (DPAP)",
    id: "5",
    contentP1:
      "Panoorin ang mensahe ni VP-Secretary Sara Z. Duterte at alamin kung ano nga ba ang DepEd Partnership Assistance Portal (DPAP) na inilunsad ng Kagawaran ng Edukasyon (DepEd) kahapon, Marso 23.",
    contentP2:
      "Ang pagbuo ng DPAP ay bilang pagbibigay prayoridad sa kahalagahan ng partnership at collaboration sa paghahatid ng dekalidad na edukasyon sa pamamagitan ng mas maayos na sistema ng proseso para sa mga DepEd partners.",
     
      photoCover5: photoCover5,
  },
  {
    title: "𝗟𝗼𝗰𝗮𝗹 𝗽𝗮𝗿𝘁𝗻𝗲𝗿𝘀 𝗻𝗮𝗴𝗽𝗮𝗵𝗮𝘆𝗮𝗴 𝗻𝗴 𝘀𝘂𝗽𝗼𝗿𝘁𝗮 𝘂𝗽𝗮𝗻𝗴 𝗽𝗮𝗹𝗮𝗸𝗮𝘀𝗶𝗻 𝗮𝗻𝗴 𝗠𝗔𝗧𝗔𝗧𝗔𝗚 𝗔𝗴𝗲𝗻𝗱𝗮 𝗽𝗿𝗼𝗴𝗿𝗮𝗺𝘀",
    id: "6",
    contentP1:
      "PASIG, CITY, Abril 27, 2023 — Muling nasiguro ng Kagawaran ng Edukasyon (DepEd) ang pangako ng mga local partner organization sa pagbibigay ng kaukulang tulong sa MATATAG Agenda sa isang consultative meeting na ginanap nitong Martes, Abril 25, sa Bulwagan ng Karunungan sa Central Office.",
    contentP2:
      "Inilatag ng forum sa mga lokal partner ang MATATAG Agenda at iprinisinta ang mga iminungkahing menu of intervention sa edukasyon na maaaring magawa sa pamamagitan ng pakikipagtulungan.",
    contentP3:
      "Sa pamamagitan ng consultative meeting, parehong napag-usapan ng DepEd at partners ang pamamahagi at pag-uuri ng mga programa at proyekto ng mga local partner mula sa partiality sa mga rehiyon, antas ng grado, uri ng programa, at saklaw ng proyekto.",
    contentP4:
      "The MATATAG Agenda is not merely a dream, it is a commitment, so now more than ever, we need your continued collaboration, pahayag ni DepEd Chief of Staff at Spokesperson Michael Poa.",
    contentP5:
      "Tinalakay ng mga kinatawan ng DepEd local partners ang kanilang mga kasalukuyang programa at ang pagkakaugnay nito sa MATATAG Agenda, habang ang iba ay nagpahayag ng interes sa kung anong mga karagdagang programa ang maaaring idagdag sa roster. Ang bagong menu of interventions ay nagbigay ng kinakailangang plataporma para sa mga partner upang matukoy at maiayon ang kanilang mga proyekto pabor sa bagong education agenda ng Kagawaran.",  
    contentP6:
      "Inihayag ng mga partner ang kanilang kahandaan na tumulong sa pagsisiguro ng kapakanan ng mga guro sa pamamagitan ng paghahain ng mga programa para sa capacity-building, scholarships, financial literacy, at kalusugan ng mga guro. Bukod pa rito, ibinigay rin ng mga partner ang kanilang commitment sa pagtulong sa mga mag-aaral sa pamamagitan ng kanilang mga programa para sa kagalingan at kalusugan.",  
    contentP7:
      "Hinikayat din ng DepEd ang kanilang mga local partner na mag-sign up sa bagong DepEd Partnership and Assistance Portal (DPAP) at tingnan ang School Improvement Plan (SIP) ng iba't ibang paaralan, na maaari nilang direktang ma-access sa pamamagitan ng website.",  
    contentP8:
      "Sa pangunguna ng External Partnership Service (EPS), dinaluhan ang pagpupulong ng mga kinatawan ng 92 pribado at pampublikong partner na organisasyon at mga opisyal at kawani ng DepEd. Ito ang ikatlong pulong na inorganisa ng Kagawaran bilang paghahanda para sa paparating na Partners Forum sa Mayo 25, 2023.",  
    photoCover6: photoCover6,
  },
];

{
  /* <div class="grid grid-rows-3 grid-flow-col gap-4">
  <div class="row-span-3 ...">01</div>
  <div class="col-span-2 ...">02</div>
  <div class="row-span-2 col-span-2 ...">03</div>
</div> */
}

const ArticleCard = () => {
  return (
    
    <div className="my-16">
      <div
        key={articles[4].id}
        className="grid grid-rows-3 grid-flow-col gap-2    my-5"
      >
        <div
          style={{
            overflow: "hidden",
            marginRight: "20px",
            width: "100%",
            height: "100%",
          }}
          className="row-span-3"
        >
          <img
            src={articles[4].photoCover5}
            style={{
              width: 300,
              height: 169,
              objectFit: "cover",
              objectPosition: "center center",
              overflow: "hidden",
              display: "inline-block",
            }}
          />
        </div>
        <a href="https://www.facebook.com/watch/?v=880468686387341"  target="_blank" style={{ color: '#0038A8' }} className="col-span-2 text-2xl text-blue-600">
          {articles[4].title}
        </a>
        <p className="row-span-2 col-span-2 overflow-hidden text-ellipsis">
          {articles[4].contentP1}
          <a href="https://www.facebook.com/watch/?v=880468686387341"  target="_blank" className="text-sm text-blue-600 ml-2">
            View
          </a>
        </p>
      </div>

      <div
        key={articles[2].id}
        className="grid grid-rows-3 grid-flow-col gap-2    my-5"
      >
        <div
          style={{
            overflow: "hidden",
            marginRight: "20px",
            width: "100%",
            height: "100%",
          }}
          className="row-span-3"
        >
          <img
            src={articles[2].photoCover33}
            style={{
              width: 300,
              height: 169,
              objectFit: "cover",
              objectPosition: "center center",
              overflow: "hidden",
              display: "inline-block",
            }}
          />
        </div>
        <a href="/article3" style={{ color: '#0038A8' }} className="col-span-2 text-2xl text-blue-600">
          {articles[2].title}
        </a>
        <p className="row-span-2 col-span-2 overflow-hidden text-ellipsis">
          {articles[2].contentP1}
          <a href="/article3" className="text-sm text-blue-600 ml-2">
            Read More
          </a>
        </p>
      </div>

      <div
        key={articles[5].id}
        className="grid grid-rows-3 grid-flow-col gap-2    my-5"
      >
        <div
          style={{
            overflow: "hidden",
            marginRight: "20px",
            width: "100%",
            height: "100%",
          }}
          className="row-span-3"
        >
          <img
            src={articles[5].photoCover6}
            style={{
              width: 300,
              height: 169,
              objectFit: "cover",
              objectPosition: "center center",
              overflow: "hidden",
              display: "inline-block",
            }}
          />
        </div>
        <a href="/article6" style={{ color: '#0038A8' }} className="col-span-2 text-2xl text-blue-600">
          {articles[5].title}
        </a>
        <p className="row-span-2 col-span-2 overflow-hidden text-ellipsis">
          {articles[5].contentP1}
          <a href="/article6" className="text-sm text-blue-600 ml-2">
            Read More
          </a>
        </p>
      </div>

      <div
        key={articles[3].id}
        className="grid grid-rows-3 grid-flow-col gap-2    my-5"
      >
        <div
          style={{
            overflow: "hidden",
            marginRight: "20px",
            width: "100%",
            height: "100%",
          }}
          className="row-span-3"
        >
          <img
            src={articles[3].photoCover4}
            style={{
              width: 300,
              height: 169,
              objectFit: "cover",
              objectPosition: "center center",
              overflow: "hidden",
              display: "inline-block",
            }}
          />
        </div>
        <a href="/article4" style={{ color: '#0038A8' }} className="col-span-2 text-2xl text-blue-600">
          {articles[3].title}
        </a>
        <p className="row-span-2 col-span-2 overflow-hidden text-ellipsis">
          {articles[3].contentP1}
          <a href="/article4" className="text-sm text-blue-600 ml-2">
            Read More
          </a>
        </p>
      </div>

      <div
        key={articles[0].id}
        className="grid grid-rows-3 grid-flow-col gap-2    my-5"
      >
        <div
          style={{
            overflow: "hidden",
            marginRight: "20px",
            width: "100%",
            height: "100%",
          }}
          className="row-span-3"
        >
          <img
            src={articles[0].photoCover1}
            style={{
              width: 300,
              height: 169,
              objectFit: "cover",
              objectPosition: "center center",
              overflow: "hidden",
              display: "inline-block",
            }}
          />
        </div>
        <a style={{ color: '#0038A8' }} href="/article1" className="col-span-2 text-2xl text-blue-600">
          {articles[0].title}
        </a>
        <p className="row-span-2 col-span-2 overflow-hidden text-ellipsis">
          {articles[0].contentP1}
          <a href="/article1" className="text-sm text-blue-600 ml-2">
            Read More
          </a>
        </p>
      </div>

      <div
        key={articles[1].id}
        className="grid grid-rows-3 grid-flow-col gap-2    my-5"
      >
        <div
          style={{
            overflow: "hidden",
            marginRight: "20px",
            width: "100%",
            height: "100%",
          }}
          className="row-span-3"
        >
          <img
            src={articles[1].photoCover1}
            style={{
              width: 300,
              height: 169,
              objectFit: "cover",
              objectPosition: "center center",
              overflow: "hidden",
              display: "inline-block",
            }}
          />
        </div>
        <a href="/article2" style={{ color: '#0038A8' }} className="col-span-2 text-2xl text-blue-600">
          {articles[1].title}
        </a>
        <p className="row-span-2 col-span-2 overflow-hidden text-ellipsis">
          {articles[1].contentP1}
          <a href="/article2" className="text-sm text-blue-600 ml-2">
            Read More
          </a>
        </p>
      </div>

    </div>
  );
};

export default ArticleCard;
