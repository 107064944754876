const data = [
  {
    title: 'A. ADOPT-A-SCHOOL PROGRAM (ASP)',
    heading: 'FREQUENTLY ASKED QUESTIONS:',
    description: [
      {
        Q: `1. What are the benefits an adopting private entity can avail of under the Adopt-a-School Program?`,
        A: `Allows private entities to assist a public school in staff and faculty development for training and further education; construction facilities; upgrading of existing facilities; provision of books, publications, and other instructional materials; and modernization of instructional technologies.`,
      },
      {
        Q: `2. Where can a private entity obtain the certification/ endorsement so that it can avail of the tax incentives as provided for under the Adopt-a-School Law?`,
        A: `The private entity should request an endorsement from the DepEd Central Office in Pasig through the Adopt-a-School Secretariat under the External Partnerships Service. The endorsement is approved by DepEd`,
      },
      {
        Q: `3. What are the requirements needed by the Revenue District Office of the Bureau of Internal Revenue (BIR) in considering the application of the private entity for tax incentives?`,
        A: `The private entity applying for tax incentives must submit the following:
                - Duly notarized/ approved Agreement;
                - Duly notarized Deed of Donation; 
                - Duly notarized Deed of Acceptance;  
                - Official receipts showing the actual value of the donation; and 
                - Certificate of title and tax declaration if the donation is in the form of real property.`,
      },
      {
        Q: `4. For donations coming from overseas, what are the documents required to avail of duty-free entry of goods?`,
        A: `The documents required prior to the issuance of duty-free certification by DepEd and the Department of Finance (DOF) are the following: 

                From the Donor: 
                    o Bill of Lading, Air Waybill, Parcel Notice, or other shipping documents; and 
                    o Commercial Invoice and Packing List 
                From the Donee: 
                    o Notarized Deed of Donation, Deed of Acceptance, and Memorandum of Agreement 	(or Memorandum of Understanding, whichever is applicable).`,
      },
      {
        Q: `5. Who can help?`,
        A: `A private individual, group, organization, or institution can become partners in education. There are, however, basic qualifications of participants to the Program. A private entity that intends to participate should have been in existence for at least a year, and have a credible track record to be supported by a Certificate of Registration with the Securities and Exchange Commission (SEC), or with the Cooperative Development Authority (CDA)`,
      },
      {
        Q: `6. What assistance can be provided?`,
        A: `The program carries a menu of options called packages that a stakeholder can select from. Packages range from building classrooms, laboratories, staff development, nutrition programs, e-learning, to the provision of instructional materials. The packages are designed to meet the school’s needs in accordance with the private sector’s capacity and thrusts.`,
      },
      {
        Q: `7. Which schools need assistance?`,
        A: `Any government school whether elementary, secondary, post-secondary, or tertiary, preferably located in any of the current poorest provinces or municipalities can avail of the donations under the Program. Priority shall be given to schools located in the poorest provinces as determined by the National Statistical Coordination Board (NCSB); low income municipalities and other schools with undernourished learners; provinces or municipalities with indigent but high performing learners, schools with sever classroom shortage, and schools with insufficient funds and/ or deficient supply of textbooks and other instructional materials both for teachers and learners.`,
      },
      {
        Q: `8. How will recipient schools be selected?`,
        A: `The adopting private entity has the prerogative of identifying the school of its choice, as well as the area and geographic location where it wishes to place its support. In case the adopting private entity has no particular school in mind, any of the three education agencies will provide the adopting entity a list of priority schools and their identified needs.`,
      },
    ],
  },
  {
    title: 'B. BRIGADA ESKWELA PROGRAM',
    heading: 'FREQUENTLY ASKED QUESTIONS:',
    description: [
      {
        Q: `1. What are the benefits an adopting private entity can avail of under the Adopt-a-School Program?`,
        A: `Allows private entities to assist a public school in staff and faculty development for training and further education; construction facilities; upgrading of existing facilities; provision of books, publications, and other instructional materials; and modernization of instructional technologies.`,
      },
      {
        Q: `2. Where can a private entity obtain the certification/ endorsement so that it can avail of the tax incentives as provided for under the Adopt-a-School Law?`,
        A: `The private entity should request an endorsement from the DepEd Central Office in Pasig through the Adopt-a-School Secretariat under the External Partnerships Service. The endorsement is approved by DepEd`,
      },
      {
        Q: `3. What are the requirements needed by the Revenue District Office of the Bureau of Internal Revenue (BIR) in considering the application of the private entity for tax incentives?`,
        A: `The private entity applying for tax incentives must submit the following:
                - Duly notarized/ approved Agreement;
                - Duly notarized Deed of Donation; 
                - Duly notarized Deed of Acceptance;  
                - Official receipts showing the actual value of the donation; and 
                - Certificate of title and tax declaration if the donation is in the form of real property.`,
      },
      {
        Q: `4. For donations coming from overseas, what are the documents required to avail of duty-free entry of goods?`,
        A: `The documents required prior to the issuance of duty-free certification by DepEd and the Department of Finance (DOF) are the following: 

                From the Donor: 
                    o Bill of Lading, Air Waybill, Parcel Notice, or other shipping documents; and 
                    o Commercial Invoice and Packing List 
                From the Donee: 
                    o Notarized Deed of Donation, Deed of Acceptance, and Memorandum of Agreement 	(or Memorandum of Understanding, whichever is applicable).`,
      },
      {
        Q: `5. Who can help?`,
        A: `A private individual, group, organization, or institution can become partners in education. There are, however, basic qualifications of participants to the Program. A private entity that intends to participate should have been in existence for at least a year, and have a credible track record to be supported by a Certificate of Registration with the Securities and Exchange Commission (SEC), or with the Cooperative Development Authority (CDA)`,
      },
      {
        Q: `6. What assistance can be provided?`,
        A: `The program carries a menu of options called packages that a stakeholder can select from. Packages range from building classrooms, laboratories, staff development, nutrition programs, e-learning, to the provision of instructional materials. The packages are designed to meet the school’s needs in accordance with the private sector’s capacity and thrusts.`,
      },
      {
        Q: `7. Which schools need assistance?`,
        A: `Any government school whether elementary, secondary, post-secondary, or tertiary, preferably located in any of the current poorest provinces or municipalities can avail of the donations under the Program. Priority shall be given to schools located in the poorest provinces as determined by the National Statistical Coordination Board (NCSB); low income municipalities and other schools with undernourished learners; provinces or municipalities with indigent but high performing learners, schools with sever classroom shortage, and schools with insufficient funds and/ or deficient supply of textbooks and other instructional materials both for teachers and learners.`,
      },
      {
        Q: `8. How will recipient schools be selected?`,
        A: `The adopting private entity has the prerogative of identifying the school of its choice, as well as the area and geographic location where it wishes to place its support. In case the adopting private entity has no particular school in mind, any of the three education agencies will provide the adopting entity a list of priority schools and their identified needs.`,
      },
    ],
  },
  {
    title: 'C. BRIGADA PAGBASA PROGRAM',
    heading: 'FREQUENTLY ASKED QUESTIONS:',
    description: [
      {
        Q: `1. What are the benefits an adopting private entity can avail of under the Adopt-a-School Program?`,
        A: `Allows private entities to assist a public school in staff and faculty development for training and further education; construction facilities; upgrading of existing facilities; provision of books, publications, and other instructional materials; and modernization of instructional technologies.`,
      },
      {
        Q: `2. Where can a private entity obtain the certification/ endorsement so that it can avail of the tax incentives as provided for under the Adopt-a-School Law?`,
        A: `The private entity should request an endorsement from the DepEd Central Office in Pasig through the Adopt-a-School Secretariat under the External Partnerships Service. The endorsement is approved by DepEd`,
      },
      {
        Q: `3. What are the requirements needed by the Revenue District Office of the Bureau of Internal Revenue (BIR) in considering the application of the private entity for tax incentives?`,
        A: `The private entity applying for tax incentives must submit the following:
                - Duly notarized/ approved Agreement;
                - Duly notarized Deed of Donation; 
                - Duly notarized Deed of Acceptance;  
                - Official receipts showing the actual value of the donation; and 
                - Certificate of title and tax declaration if the donation is in the form of real property.`,
      },
      {
        Q: `4. For donations coming from overseas, what are the documents required to avail of duty-free entry of goods?`,
        A: `The documents required prior to the issuance of duty-free certification by DepEd and the Department of Finance (DOF) are the following: 

                From the Donor: 
                    o Bill of Lading, Air Waybill, Parcel Notice, or other shipping documents; and 
                    o Commercial Invoice and Packing List 
                From the Donee: 
                    o Notarized Deed of Donation, Deed of Acceptance, and Memorandum of Agreement 	(or Memorandum of Understanding, whichever is applicable).`,
      },
      {
        Q: `5. Who can help?`,
        A: `A private individual, group, organization, or institution can become partners in education. There are, however, basic qualifications of participants to the Program. A private entity that intends to participate should have been in existence for at least a year, and have a credible track record to be supported by a Certificate of Registration with the Securities and Exchange Commission (SEC), or with the Cooperative Development Authority (CDA)`,
      },
      {
        Q: `6. What assistance can be provided?`,
        A: `The program carries a menu of options called packages that a stakeholder can select from. Packages range from building classrooms, laboratories, staff development, nutrition programs, e-learning, to the provision of instructional materials. The packages are designed to meet the school’s needs in accordance with the private sector’s capacity and thrusts.`,
      },
      {
        Q: `7. Which schools need assistance?`,
        A: `Any government school whether elementary, secondary, post-secondary, or tertiary, preferably located in any of the current poorest provinces or municipalities can avail of the donations under the Program. Priority shall be given to schools located in the poorest provinces as determined by the National Statistical Coordination Board (NCSB); low income municipalities and other schools with undernourished learners; provinces or municipalities with indigent but high performing learners, schools with sever classroom shortage, and schools with insufficient funds and/ or deficient supply of textbooks and other instructional materials both for teachers and learners.`,
      },
      {
        Q: `8. How will recipient schools be selected?`,
        A: `The adopting private entity has the prerogative of identifying the school of its choice, as well as the area and geographic location where it wishes to place its support. In case the adopting private entity has no particular school in mind, any of the three education agencies will provide the adopting entity a list of priority schools and their identified needs.`,
      },
    ],
  },
];

export default data;
