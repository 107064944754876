import React from "react";
import { Button } from "antd";
import image from "../../../shared/images/Assets_DepEd-DPDS/Title.png";
const OverViewComponent = () => {
  return (
    <div className="ct-container">
      <div className="py-20">
        <div className="font-bold flex justify-between items-center w-full mt-20 header-border">
          <div>
            <img src={image} />
            <p className="absolute ml-5 -mt-2 text-2xl uppercase">
              External Partnership Service (EPS)
            </p>
          </div>
          {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded align-self-center">
            View More
          </button> */}
        </div>
        <div className="mt-5">
          <p>
            The External Partnership Service serves as the clearing house for
            all local, national and international transactions on partnerships
            and external linkages including bilateral or multilateral
            cooperation. The Undersecretary directly monitors and supervises the
            tasks and duties being implemented by the EPS which requires the
            Undersecretary to continue overseeing the operational activities and
            coordinate the programs and projects of the Office. All other
            personnel under EPS catering to government sector partnerships,
            private sector partnerships, special events, and other concerns
            pertaining to partnerships are directly being handled by the
            Undersecretary.
          </p>

          <div className="space-y-10 mt-10">
            <div>
              <p>Specific functions of the EPS include the following:</p>
              <table className="border-collapse border w-full">
                {/* <ol className="list-decimal"> */}
                <tr>
                  <th className="bg-blue-10 text-white border text-center px-3 py-2">
                    Key Result Area/s
                  </th>
                  <th className="bg-blue-10 text-white border text-center px-3 py-2">
                    Objectives
                  </th>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    KRA 1: Partnerships Development and Management
                  </td>
                  <td className="border px-3 py-2 align-top">
                    <p>
                      1. To develop the national education framework on
                      establishing partnerships
                    </p>
                    <p>
                      2. To engage potential external partners from among
                      stakeholders and formalize partnerships
                    </p>
                    <p>
                      3. To serve as clearing house for all partnerships within
                      the Department
                    </p>
                    <p>
                      4. To develop database and knowledge management system for
                      local partnerships in coordination with concerned/relevant
                      offices
                    </p>
                    <p>
                      5. To strengthen the implementation of Programs, Projects,
                      and Activities (PPAs) through the development of M & E
                      tools
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    KRA 2: Commemorative and Other Related Events Management{" "}
                  </td>
                  <td className="border px-3 py-2 align-top">
                    <p>
                      6. To provide technical, management and administrative
                      support to special events organized and co-organized by
                      DepEd
                    </p>
                    <p>
                      7. To ensure compliance and commitments of DepEd on
                      national and local observances of historical events and
                      commemorations
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    KRA 3: Technical Assistance on External Partnerships{" "}
                  </td>
                  <td className="border px-3 py-2 align-top">
                    <p>
                      8. To identify partnership-related learning and
                      development needs of DepEd partnerships focal persons
                      across all governance levels
                    </p>
                    <p>
                      9. To capacitate DepEd units on the process of
                      establishing partnership and linkages in coordination with
                      concerned offices
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    KRA 4: Performance Management
                  </td>
                  <td className="border px-3 py-2 align-top">
                    <p>
                      10. To improve EPS staff performance through utilization
                      of the Result-based Performance Management System (RPMS)
                    </p>
                    <p>
                      11. To provide staff development opportunities to EPS
                      personnel through meetings, consultations, and provision
                      of relevant training programs
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    KRA 5: Office Administration{" "}
                  </td>
                  <td className="border px-3 py-2 align-top">
                    <p>
                      12. To establish and implement office systems and
                      processes aimed to effective and efficient office
                      administration
                    </p>
                    <p>
                      13. To ensure that administration, budget, finance and
                      other concerned offices’ relevant processes and
                      requirements are met
                    </p>
                  </td>
                </tr>
                {/* </ol> */}
              </table>
            </div>
            <div>
              <p>EXTERNAL PARTNERSHIPS SERVICE PERSONNEL</p>
              <table className="table-auto w-full border-collapse border">
                <tr>
                  <th className="bg-blue-10 text-white border text-center px-3 py-2">
                    EPS Unit
                  </th>
                  <th className="bg-blue-10 text-white border text-center px-3 py-2">
                    Name
                  </th>
                  <th className="bg-blue-10 text-white border text-center px-3 py-2">
                    Position/Designation
                  </th>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top" rowSpan={6}>
                    Office of the Director
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Margarita Consolacion C. Ballesteros
                  </td>
                  <td className="border px-3 py-2 align-top">Director IV</td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Rolly V. Soriano
                  </td>
                  <td className="border px-3 py-2 align-top">PDO IV</td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Crisalyn De Leon
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Senior Technical Assistant I
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">Mary Ann Bruan</td>
                  <td className="border px-3 py-2 align-top">
                    Technical Assistant II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Edilyn Dalogdog
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Administrative Support Staff I
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top" rowSpan={2}>
                    ICO-Admin
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Maurene Gaye Obrero
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Technical Assistant I
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Josephine Gesite
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Administrative Assistant I
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top" rowSpan={2}>
                    ICO-Bilateral
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Romeo Tuddao Jr.
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Senior Technical Assistant III
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Frederick Orticio
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Project Development Officer III
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top" rowSpan={2}>
                    ICO ASEAN/ SEAMEO
                  </td>
                  <td className="border px-3 py-2 align-top">Maribeth Chua</td>
                  <td className="border px-3 py-2 align-top">
                    Project Development Officer II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Hanna Patricia Cortes
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Technical Assistant II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top" rowSpan={3}>
                    ICO- UN/ UNESCO
                  </td>
                  <td className="border px-3 py-2 align-top">Joan Tacadena</td>
                  <td className="border px-3 py-2 align-top">
                    Project Development Officer I
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">Cressa Zamora</td>
                  <td className="border px-3 py-2 align-top">
                    Senior Technical Assistant I
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">Brandon Paler</td>
                  <td className="border px-3 py-2 align-top">
                    Technical Assistant I
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top" rowSpan={6}>
                    Private Sector Partnerships Unit (PSPU)
                  </td>
                  <td className="border px-3 py-2 align-top">Zeny Lastimoza</td>
                  <td className="border px-3 py-2 align-top">
                    Project Development Officer II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Caroline Labrague
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Project Development Officer II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Davedinah Cometa
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Project Development Officer II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Jan Antonio Casugbo
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Administrative Support Staff II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">Rudy Calgao</td>
                  <td className="border px-3 py-2 align-top">
                    Technical Assistant II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Rex August Fernandez
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Technical Assistant II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top" rowSpan={6}>
                    Government and Community Partnerships Unit (GCPU)
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Jho-ana A. Llana
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Project Development Officer II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">Jannet Labre</td>
                  <td className="border px-3 py-2 align-top">
                    Project Development Officer II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Sabina De Castro
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Project Development Officer II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Cecilia Mendiola
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Project Development Officer II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Romeo Granadozin
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Administrative Support Staff II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top"></td>
                  <td className="border px-3 py-2 align-top"></td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top" rowSpan={6}>
                    Special Events Unit (SEU)
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Blanquita Dolores Fontanilla
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Chief Administrative Officer
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">Norman Asenci</td>
                  <td className="border px-3 py-2 align-top">
                    Senior Technical Assistant I
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">
                    Ana Liza Jorduela
                  </td>
                  <td className="border px-3 py-2 align-top">
                    Technical Assistant II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">Joel Faustino</td>
                  <td className="border px-3 py-2 align-top">
                    Administrative Support Staff II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top">Dante Raposa</td>
                  <td className="border px-3 py-2 align-top">
                    Administrative Assistant II
                  </td>
                </tr>
                <tr>
                  <td className="border px-3 py-2 align-top"></td>
                  <td className="border px-3 py-2 align-top"></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverViewComponent;
