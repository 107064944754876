import React from 'react'
import { Link } from 'react-router-dom'
import PublicDashboardMap from '../components/PublicDashboardMap'
import ListRegion from '../components/ListRegion'
import DahsboardChart from '../components/Chart'
import StandardLanding from '../../../shared/components/StandardLanding'

const PublicDashboard = () => {
    return (
        <StandardLanding>
            <div className="">
                <div className="main-banner flex flex-col items-center justify-center text-white w-full">
                    <p className="font-bold text-4xl mb-5">PUBLIC DASHBOARD</p>
                    <div className="flex font-bold">
                        <Link to="/">Home</Link>
                        <span className="mx-5">|</span>
                        <Link to="/about-overview">DepEd Partners</Link>
                    </div>
                </div>
                <div className="">
                    <div className="my-10">
                        <DahsboardChart />
                    </div>
                </div>

            </div>
        </StandardLanding>
    )
}

export default PublicDashboard
