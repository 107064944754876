import React, { useState } from "react";
import { Button, Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import image from "../../../shared/images/Assets_DepEd-DPDS/Title.png";
import data from "../data/data";

const ListFaqs = () => {
  const { Panel } = Collapse;

  return (
    <div className="w-full faq-list">
      {data?.map((item, i) => {
        return (
          <>
            <div>
              <p className="ml-5 mt-5 mb-3">
                <h1 className="font-semibold text-xl" key={i}>
                  {item.title}
                </h1>
              </p>
            </div>
            {item.description?.map((desc, i) => {
              return (
                <div className="m-5">
                  <Collapse
                    bordered={false}
                    // defaultActiveKey={['1']}
                    expandIconPosition="right"
                    expandIcon={({ isActive = true }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    className="site-collapse-custom-collapse"
                  >
                    <Panel
                      header={
                        <h4 className="pr-10 text-base font-medium">
                          {desc.Q}
                        </h4>
                      }
                      key="1"
                      className="site-collapse-custom-panel m-5"
                    >
                      <p className="ml-5 pr-10 text-base font-normal">{`- ${desc.A}`}</p>
                    </Panel>
                  </Collapse>
                </div>
              );
            })}
          </>
        );
      })}
      {/* <div className="my-10">
        <p className="font-bold text-lg">
          HOW CAN I HELP IMPROVE BASIC EDUCATION?
        </p>
        <div className="mt-10">
          <p className="font-bold text-lg mb-5">Popular Keyword</p>
          <div>
            <Button className="border-blue-10 h-10 mr-5">
              K TO 12 General Information
            </Button>
            <Button className="border-blue-10 h-10 mr-5">Curriculum</Button>
            <Button className="border-blue-10 h-10 mr-5">
              Kindergarten Concerns
            </Button>
            <Button className="border-blue-10 h-10 mr-5">
              Senior High School
            </Button>
            <Button className="border-blue-10 h-10">Teachers</Button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ListFaqs;
