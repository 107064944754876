import React from "react";
import { Button } from "antd";
import image from "../../../shared/images/Assets_DepEd-DPDS/Title.png";
import OverviewOfPartnershipImg from "../../../shared/images/Assets_DepEd-DPDS/Overview-of-Partnership.png";
const OverViewComponent = () => {
  return (
    <div className="ct-container">
      <div className="py-20">
        <div className="font-bold flex justify-between items-center w-full mt-20 header-border">
          <div>
            <img src={image} />
            <p className="absolute ml-5 -mt-2 text-2xl uppercase">
              Overview of partnership
            </p>
          </div>
          {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded align-self-center">
            View More
          </button> */}
        </div>
        <div className="mt-5">
          <div className="flex justify-center">
            <img src={OverviewOfPartnershipImg} />
          </div>
          <p className="mt-10">
            “Partnership,” as defined under Article 1767 of the Philippine Civil
            Code is a binding contract among two or more people to “contribute
            money, property, or industry to a common fund, with the intention of
            dividing the profits among themselves.” Thus, partnership implies an
            agreement between two or more parties to work together to achieve a
            common goal.
          </p>
          <p className="mt-10">
            Moreover, partnership may also refer to the product of collaborative
            processes within an inter-organizational network and successful
            partnerships lead to a creation of a “second order organization”
            (Imperial, 2005). Essentially, when a group of individuals makes
            joint decisions, embraces collaboration and forms strategic
            alliances (Dyer & Singh, 1998, Osborn & Hagedoorn, 1997, and Gulati,
            1995), they are in effect acting as a new organization (Jones,
            et.al., 1997 and Finn, 1996).
          </p>
          <p className="mt-10">
            Various partnership agreements entered into by DepEd have
            contributed greatly to the success of educational programs. These
            partnerships have addressed critical needs, such as classrooms,
            training of teachers, provision of equipment and construction of
            other facilities required for the efficient and effective
            implementation of the different programs, projects and activities.
            It is indeed important for DepEd to explore diverse ways of
            financing and providing educational services and one of the possible
            solutions is through partnership.
          </p>

          <p className="mt-10">
            As DepEd continuously finds ways to solve the challenges faced by
            Philippine education, partnership has become an important paradigm
            in delivering and achieving the country’s development goals and
            services. Through partnership, the school communities work together
            to provide additional resources to address the immediate needs of
            the learners. The collaboration of all education stakeholders is the
            key to ensure that our dream of having quality education for all
            shall be achieved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OverViewComponent;
