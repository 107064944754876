import React from 'react';
import { Button } from 'antd';
import image from '../../../shared/images/Assets_DepEd-DPDS/Title.png';

const OverViewComponent = () => {
  return (
    <div className="ct-container">
      <div className="py-20">
        <div className="font-bold flex justify-between items-center w-full mt-20 header-border">
          <div>
            <img src={image} />
            <p className="absolute ml-5 -mt-2 text-2xl">TITLE NAME HER</p>
          </div>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded align-self-center">
            View More
          </button>
        </div>
        <p className="mt-10">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Dignissim
          convallis aenean et tortor at. Elementum eu facilisis sed odio morbi
          quis. Ultricies lacus sed turpis tincidunt id. Augue lacus viverra
          vitae congue eu. Praesent elementum facilisis leo vel fringilla.
          Semper viverra nam libero justo laoreet. Eleifend donec pretium
          vulputate sapien. Dapibus ultrices in iaculis nunc sed augue lacus.
          Lorem donec massa sapien faucibus. Tincidunt eget nullam non nisi est
          sit amet facilisis. Quis vel eros donec ac. Sed libero enim sed
          faucibus turpis. Purus non enim praesent elementum facilisis leo vel.
          Sit amet luctus venenatis lectus magna fringilla urna porttitor
          rhoncus. Ut eu sem integer vitae justo. Faucibus nisl tincidunt eget
          nullam non. Dui faucibus in ornare quam viverra orci. Dapibus ultrices
          in iaculis nunc sed augue lacus viverra. Et magnis dis parturient
          montes nascetur ridiculus mus mauris. Neque gravida in fermentum et
          sollicitudin ac orci phasellus. Aliquam sem fringilla ut morbi. Elit
          ut aliquam purus sit amet luctus venenatis lectus. Et malesuada fames
          ac turpis egestas. Posuere morbi leo urna molestie at elementum.
          Sodales ut eu sem integer vitae. Nibh ipsum consequat nisl vel pretium
          lectus quam. Nunc congue nisi vitae suscipit. Sollicitudin aliquam
          ultrices sagittis orci. Diam quis enim lobortis scelerisque fermentum.
          Eget nullam non nisi est sit amet facilisis. Enim ut sem viverra
          aliquet. At tellus at urna condimentum mattis pellentesque. Id porta
          nibh venenatis cras. Nulla malesuada pellentesque elit eget gravida
          cum sociis natoque penatibus. Arcu ac tortor dignissim convallis
          aenean et tortor at risus. Ac tortor dignissim convallis aenean et
          tortor at risus. Ullamcorper malesuada proin libero nunc consequat
          interdum varius. Nunc sed blandit libero volutpat sed cras. Enim neque
          volutpat ac tincidunt vitae semper. Imperdiet proin fermentum leo vel.
          Maecenas sed enim ut sem viverra aliquet eget. Accumsan tortor posuere
          ac ut. Aliquet eget sit amet tellus. Amet commodo nulla facilisi
          nullam. Facilisi morbi tempus iaculis urna id volutpat lacus laoreet.
          Pretium nibh ipsum consequat nisl vel pretium lectus. Tristique
          senectus et netus et. Urna id volutpat lacus laoreet non. Amet cursus
          sit amet dictum sit amet justo. Gravida in fermentum et sollicitudin.
          Scelerisque eu ultrices vitae auctor. Bibendum neque egestas congue
          quisque egestas diam. Est sit amet facilisis magna. Facilisis magna
          etiam tempor orci eu lobortis elementum nibh tellus. Tristique
          senectus et netus et. Dictumst quisque sagittis purus sit amet. Nibh
          praesent tristique magna sit amet purus gravida. Sit amet cursus sit
          amet dictum sit amet justo. Aliquam malesuada bibendum arcu vitae
          elementum curabitur vitae. Massa tincidunt nunc pulvinar sapien et
          ligula ullamcorper. Pulvinar neque laoreet suspendisse interdum
          consectetur. Phasellus faucibus scelerisque eleifend donec pretium
          vulputate sapien. Malesuada proin libero nunc consequat interdum
          varius sit amet. Massa eget egestas purus viverra accumsan in nisl.
          Egestas quis ipsum suspendisse ultrices gravida. Scelerisque varius
          morbi enim nunc. Ultrices eros in cursus turpis massa tincidunt dui ut
          ornare. At quis risus sed vulputate odio ut enim blandit. Risus nec
          feugiat in fermentum posuere. Ac placerat vestibulum lectus mauris
          ultrices eros in cursus. Ultrices vitae auctor eu augue ut lectus arcu
          bibendum. Et pharetra pharetra massa massa ultricies. Tristique risus
          nec feugiat in fermentum posuere. Eget duis at tellus at urna
          condimentum. Amet est placerat in egestas erat imperdiet sed. Rhoncus
          dolor purus non enim praesent elementum. Dictumst quisque sagittis
          purus sit. Pellentesque massa placerat duis ultricies. Commodo quis
          imperdiet massa tincidunt nunc.
        </p>
      </div>
    </div>
  );
};

export default OverViewComponent;
