import React from 'react';
import { articles } from './ArticleCard'; 
const ArticleContent = () => {
    const article4 = articles[3];
    return (
        <div className="text-2xl max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-20">
            <h1 className='font-bold'>{article4.title}</h1>
            <div 
                style={{
                    width: '60%',
                    height: '60%',
                    overflow: 'hidden',
                    margin: '40px auto'
                }}
                className="my-10"
            >
                <img 
                    src={article4.photoCover4} 
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center center',
                    }} 
                />
            </div>
            <p className='mb-10'>{article4.contentP1}</p>
            <p className='mb-10'>{article4.contentP2}</p>
            <p className='mb-10'>{article4.contentP3}</p>
            <p className='mb-10'>{article4.contentP4}</p>
            <p className='mb-10'>{article4.contentP5}</p>
            <p className='mb-10'>{article4.contentP6}</p>
            <p className='mb-10'>{article4.contentP7}</p>
        </div>
    );
};

export default ArticleContent;
