import React from 'react';
import { articles } from './ArticleCard'; 
const ArticleContent = () => {
    const article6 = articles[5];
    return (
        <div className="text-2xl max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-20">
            <h1 className='font-bold'>{article6.title}</h1>
            <div 
                style={{
                    width: '60%',
                    height: '60%',
                    overflow: 'hidden',
                    margin: '40px auto'
                }}
                className="my-10"
            >
                <img 
                    src={article6.photoCover6} 
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center center',
                    }} 
                />
            </div>
            <p className='mb-10'>{article6.contentP1}</p>
            <p className='mb-10'>{article6.contentP2}</p>
            <p className='mb-10'>{article6.contentP3}</p>
            <p className='mb-10'>{article6.contentP4}</p>
            <p className='mb-10'>{article6.contentP5}</p>
            <p className='mb-10'>{article6.contentP6}</p>
            <p className='mb-10'>{article6.contentP7}</p>
            <p className='mb-10'>{article6.contentP8}</p>
        </div>
    );
};

export default ArticleContent;
